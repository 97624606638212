// TS complains if this has no import or export because it doesn't look module-y enough
export function load() {
  if (window.location.pathname.indexOf("/books") === 0) {
    import("./entry-books");
  } else {
    import("./entry-react-app");
  }
}

load();
